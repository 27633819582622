<template>
  <main class="container mt-3 d-flex flex-column flex-grow-1">
    <div class="row text-justify flex-grow-1">
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mx-auto d-flex flex-column">
        <div class="col-12">
          <h2 class="m-0">
            » Auch wir waren
          </h2>
          <h2 class="px-4">einmal jung.</h2>
          <p class="ps-4 mb-4">Ob Ihr glaubt, oder nicht</p>
          <p class="mb-4 px-4">
            Da Du bei unserer Altersabfrage angegeben hat, dass Du noch keine 16
            Jahre alt bist, müssen wir Dir leider den Zutritt auf unsere Website
            verwehren.
          </p>
          <p class="px-4">
            Da wir ebenfalls Wert auf Jugendschutz legen, findest du hier alle
            Infos zur Schädlichkeit von Alkohol.
          </p>
        </div>
        <div class="col-12 text-center mt-4">
          <a href="https://www.kenn-dein-limit.de/" class="btn btn-primary shadow rounded-pill raleway-black">
            » Hier gibt´s die Infos
          </a>
        </div>
        <div class="col-12 px-4 mt-auto">
          <img
            class="img-fluid bottle"
            src="@/assets/not-legal-bottle.svg"
            alt="not-legal-bottle"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.bottle {
  height: 10rem;
}
</style>
